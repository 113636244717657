.navbar {
  top: 0vh;
  height: 0px;
  position: sticky;
  z-index: 1000;
}
.navbar * {
  overflow-x: visible !important;
}
.navbar .navbar-logo {
  font-family: "Montserrat", monospace;
  margin-left: 2em;
  color: #ffffff;
  font-size: 2em;
  position: relative;
  /* top: 1vh; */
  height: 5vh;
  /* width: 5vh; */
  cursor: pointer;
}
/* #8200b6, #cc14ff, #ff00e0) */
.navbar-logo span {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
/*
.navbar-logo span:nth-child(1) {
  color: rgb(199, 128, 199);
}
.navbar-logo span:nth-child(2) {
  color: rgb(131, 131, 237);
}
.navbar-logo span:nth-child(3) {
  color: #b600b6;
}
.navbar-logo span:nth-child(4) {
  color: #e435fb;
}
.navbar-logo span:nth-child(5) {
  color: #e435fb;
}
.navbar-logo span:nth-child(6) {
  color: #e435fb;
}
.navbar-logo span:nth-child(7) {
  color: #e435fb;
}
.navbar-logo span:nth-child(8) {
  color: #e435fb;
}
.navbar-logo span:nth-child(9) {
  color: #e435fb;
}
.navbar-logo span:nth-child(10) {
  color: #e435fb;
}
.navbar-logo span:nth-child(11) {
  color: #e435fb;
}
.navbar-logo span:nth-child(12) {
  color: #ff00e0;
} */

/* .navbar .navbar-logo:hover {
  background: -webkit-linear-gradient(#8200b6, #cc14ff, #ff00e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
} */

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 2em #fff, 0 0 2.5em #fff, 0 0 3em #fff;
  }

  to {
    text-shadow: 0 0 2.5em #fff, 0 0 3em #fff;
  }
}
.navbar .navbar-content {
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  background-color: black;
}
.navbar .links-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 10em;
}
.navbar .links-container .link {
  font-size: 1.3em;
  font-family: "Montserrat", monospace;
  margin-left: 1em;
  padding-left: 0.75em;
  cursor: pointer;
  color: white;
}

.navbar .links-container .link:hover {
  color: #d64d4d;
  transform: rotate(-15deg);
}

.navbar-circle-container {
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 20px;
  /* top: 50px; */
  top: 0.5em;
  margin-left: 3.5em;
}
/* @media only screen and (max-width: 985px) {
  .navbar-circle-container {
    top: -10em;
  }
} */
.navbar-circle {
  background-color: black;
  border-radius: 50%;
  margin: auto;
  position: absolute;
}
#navbar-circle-1 {
  height: 5vh;
  width: 5vh;
  display: center;
  z-index: 3;
  background: linear-gradient(#8200b6, #cc14ff, #ff00e0);
  animation: glowing-circle-animation 5s linear infinite;
  /* if you wanna make background nebula image*/
  /* background: linear-gradient(#5b04de, #007ea8); */
  /* background: black; */
  /* background-image: url("../../public/nebula-purple.jpg"); */
  /* background-repeat: repeat-x; */
  /* animation: glowing-circle-animation 5s linear infinite; */
}
@keyframes slide {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-3840px); /* The image width */
  }
}
@keyframes glowing-circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#navbar-circle-1 span {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--gradient-colors);
}
#navbar-circle-1 span:nth-child(1) {
  filter: blur(5px);
}
#navbar-circle-1 span:nth-child(2) {
  filter: blur(10px);
}
#navbar-circle-1 span:nth-child(3) {
  filter: blur(25px);
}
#navbar-circle-1 span:nth-child(4) {
  filter: blur(50px);
}
#navbar-circle-1:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  background: white;
  border-radius: 50%;
}

.bm-burger-button {
  visibility: hidden;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #db35f1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 0px;
  width: 0px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
/* .bm-menu-wrap {
  position: fixed;
  height: 100%;
} */

/* General sidebar styles */
.bm-menu {
  background: rgba(55, 58, 71, 0.75);
  padding: 0em 0em 0;
  font-size: 2em;
  height: 100vh !important;
  overflow: hidden !important;
}
.bm-menu-wrap {
  height: 300%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  margin-top: 10vh;
}

/* Individual item */
.bm-item {
  margin-top: 3vh;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 460px) {
  .navbar {
    overflow-x: visible;
  }
  .navbar-content {
    visibility: hidden;
    height: 0px !important;
  }

  .bm-burger-button {
    visibility: hidden;
  }
  /* .navbar .navbar-logo {
    visibility: hidden;
    width: 0px !important;
  }
  .navbar-content :nth-child(1) { 
    width: 0px !important;
  }
  .navbar-content { 
    width: 100vw !important;
  }
  .navbar .links-container {
    margin: auto;
  }
  .navbar .links-container .link {
    font-size: 1em;
    font-family: "Fredoka One", cursive;
    cursor: pointer;
    color: white;
    margin-left: 0em;
    padding-left: 0em;
    width: fit-content !important;
  }
  .navbar .links-container :nth-child(1){
    margin-left: 0em
  }
  .navbar .links-container a{
    margin-left: 1em !important;
    width: fit-content !important;
  } */
  /* .navbar {
    visibility: visible;
    position: relative;
    top: 100px;
    z-index: 100;
  } */
  body * {
    overflow-x: hidden;
  }
}
