/* TODO: make sure styles are responsive / mobile friendly */
.home-page {
  width: 100%;
  height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-page {
  width: 100%;
  height: 100vh;
  min-height: 200vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/2024cyberpunk.png"); /*Update the path to your image */
  background-color: #181420;
  background-size: cover; /* Cover the entire component */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating the background image */
}

.home-page .home-page-caption {
  color: white;
  z-index: 4;
  position: relative;
}
.home-page .home-page-logo-pic {
  width: 140vh;
  z-index: 4;
  overflow: hidden;
  position: relative;
  top: 10vh;
  left: -3vh;
}

.home-page .home-page-logo {
  font-family: "Montserrat", monospace;
  font-size: 8em;
  letter-spacing: 3px;
  position: relative;
  color: white;
  z-index: 4;
}
.home-page a {
  text-decoration: none;
}
.home-page-sign-up-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
}
.sign-up-button {
  color:white;
  position: relative;
  font-size: 1.2em;
  min-width: 200px;
  /* top: -15vh; */
  /* left: 2vh; */
  z-index: 10;
  width: 90%;
  height: 100%;
  line-height: 1.5em;
  margin: 4vh auto;
  /* margin: auto; */
  border: 3px solid white;
  border-radius: 2vh;
  background-color: #33234c;
  font-family: "Montserrat", monospace;
  font-weight: 600;
  vertical-align: middle;
  top: 70px;
  right: 5.5%;
  text-align: center;
}

@media (max-width: 768px) {
  .sign-up-button {
    left: 2%;
    margin-top: -1px;
  }
}

.sign-up-text {
  font-weight: 800;
}

#participant-button {
  margin-right: .5em;
  text-align: center;
}
#mentor-button {
  margin-left: .5em;
}

@keyframes animated_home_logo {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}

.home-page .home-page-circle-container {
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -4em;
}
.home-page .home-page-circle {
  background-color: white;
  border-radius: 50%;
  margin: auto;
  position: absolute;
}
.home-page #home-page-circle-1 {
  height: 65vh;
  width: 65vh;
  display: center;
  z-index: 3;
  background: linear-gradient(#8200b6, #cc14ff, #ff00e0);
  animation: glowing-circle-animation 5s linear infinite;
  /* if you wanna make background nebula image*/
  /* background: linear-gradient(#5b04de, #007ea8); */
  /* background: black; */
  /* background-image: url("../../public/nebula-purple.jpg"); */
  /* background-repeat: repeat-x; */
  /* animation: glowing-circle-animation 5s linear infinite; */
}
@keyframes slide {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-3840px); /* The image width */
  }
}
@keyframes glowing-circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home-page #home-page-circle-1 span {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--gradient-colors);
}
.home-page #home-page-circle-1 span:nth-child(1) {
  filter: blur(5px);
}
.home-page #home-page-circle-1 span:nth-child(2) {
  filter: blur(10px);
}
.home-page #home-page-circle-1 span:nth-child(3) {
  filter: blur(25px);
}
.home-page #home-page-circle-1 span:nth-child(4) {
  filter: blur(50px);
}
.home-page #home-page-circle-1:after {
  content: "";
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: white;
  border-radius: 50%;
}

/* in case you want more circles */
/*.home-page #home-page-circle-2 {
    height: 60vh;
    width: 60vh;
    z-index: 2;
}
.home-page #home-page-circle-3 {
    height: 20em;
    width: 20em;
    z-index: 1;
} */

@media only screen and (max-width: 460px) {
  .home-page {
    height: 100vh;
  }
  .home-page-content {
    width: 100%;
    position: fixed;
     bottom: 0;
     right: 0;

    /* left: 5vw;
    top: -10vw; */
  }
  .home-page-logo-pic {
    margin: auto;
    width: 90vw;
    position: relative;
    top: 1vw !important;
    left: 0vw !important;
  }
  .sign-up-button {
    font-size: 1.2em;
    width: 7em;
    height: 3em;
    line-height: 1.2em;
    position: relative;
    top: 10px;
    margin: 0vh auto io !important;
  }
}
@media only screen and (max-width: 460px) {
  .home-page {
    height: 100vh;
    background-position: center center; /* Adjust for very small screens */
  }
  .home-page-logo-pic {
    width: 90vw;
    position: relative;
    top: 1vw !important;
    left: 0vw !important;
  }
  .sign-up-button {
    font-size: 1.2em;
    width: 7em;
    height: 3em;
    line-height: 1.2em;
    position: fixed;
     bottom: 0;
     right: 0;
    /* top: 10px; */
    margin: 0vh auto !important;
  }
}
#stars {
  width: 2px;
  height: 5.53px;
  background-color: #000;
  border-radius: 50% / 60%; 
  position: relative;
  margin: 50px auto;
  z-index: 1;
  box-shadow:
  483px 450px 0 1px #000, 483px 450px 0 2px #ffffff,
  1995px 525px 0 1px #000, 1995px 525px 0 2px #ffffff,
  755px 1459px 0 1px #000, 755px 1459px 0 2px #ffffff,
  610px 1246px 0 1px #000, 610px 1246px 0 2px #ffffff,
  764px 1850px 0 1px #000, 764px 1850px 0 2px #ffffff,
  1274px 1697px 0 1px #000, 1274px 1697px 0 2px #ffffff,
  1227px 37px 0 1px #000, 1227px 37px 0 2px #ffffff,
  1090px 506px 0 1px #000, 1090px 506px 0 2px #ffffff,
  341px 1005px 0 1px #000, 341px 1005px 0 2px #ffffff,
  1002px 755px 0 1px #000, 1002px 755px 0 2px #ffffff,
  693px 1497px 0 1px #000, 693px 1497px 0 2px #ffffff,
  1797px 1626px 0 1px #000, 1797px 1626px 0 2px #ffffff,
  1838px 793px 0 1px #000, 1838px 793px 0 2px #ffffff,
  802px 881px 0 1px #000, 802px 881px 0 2px #ffffff,
  1117px 1983px 0 1px #000, 1117px 1983px 0 2px #ffffff,
  352px 1465px 0 1px #000, 352px 1465px 0 2px #ffffff,
  1526px 852px 0 1px #000, 1526px 852px 0 2px #ffffff,
  1559px 526px 0 1px #000, 1559px 526px 0 2px #ffffff,
  517px 516px 0 1px #000, 517px 516px 0 2px #ffffff,
  1380px 244px 0 1px #000, 1380px 244px 0 2px #ffffff,
  662px 1531px 0 1px #000, 662px 1531px 0 2px #ffffff,
  1544px 518px 0 1px #000, 1544px 518px 0 2px #ffffff,
  253px 879px 0 1px #000, 253px 879px 0 2px #ffffff,
  774px 1629px 0 1px #000, 774px 1629px 0 2px #ffffff,
  752px 994px 0 1px #000, 752px 994px 0 2px #ffffff,
  1129px 726px 0 1px #000, 1129px 726px 0 2px #ffffff,
  232px 1050px 0 1px #000, 232px 1050px 0 2px #ffffff,
  1766px 290px 0 1px #000, 1766px 290px 0 2px #ffffff,
  1976px 842px 0 1px #000, 1976px 842px 0 2px #ffffff,
  662px 1535px 0 1px #000, 662px 1535px 0 2px #ffffff,
  257px 1016px 0 1px #000, 257px 1016px 0 2px #ffffff,
  665px 708px 0 1px #000, 665px 708px 0 2px #ffffff,
  1476px 1772px 0 1px #000, 1476px 1772px 0 2px #ffffff,
  261px 498px 0 1px #000, 261px 498px 0 2px #ffffff,
  719px 1459px 0 1px #000, 719px 1459px 0 2px #ffffff,
  469px 438px 0 1px #000, 469px 438px 0 2px #ffffff,
  994px 474px 0 1px #000, 994px 474px 0 2px #ffffff,
  744px 808px 0 1px #000, 744px 808px 0 2px #ffffff,
  1609px 1741px 0 1px #000, 1609px 1741px 0 2px #ffffff,
  898px 260px 0 1px #000, 898px 260px 0 2px #ffffff,
  1978px 1436px 0 1px #000, 1978px 1436px 0 2px #ffffff,
  904px 1244px 0 1px #000, 904px 1244px 0 2px #ffffff,
  123px 773px 0 1px #000, 123px 773px 0 2px #ffffff,
  1973px 1778px 0 1px #000, 1973px 1778px 0 2px #ffffff,
  1470px 1861px 0 1px #000, 1470px 1861px 0 2px #ffffff,
  1037px 156px 0 1px #000, 1037px 156px 0 2px #ffffff,
  554px 1905px 0 1px #000, 554px 1905px 0 2px #ffffff,
  1508px 849px 0 1px #000, 1508px 849px 0 2px #ffffff,
  1213px 1603px 0 1px #000, 1213px 1603px 0 2px #ffffff,
  87px 1907px 0 1px #000, 87px 1907px 0 2px #ffffff,
  492px 527px 0 1px #000, 492px 527px 0 2px #ffffff,
  91px 213px 0 1px #000, 91px 213px 0 2px #ffffff,
  492px 15px 0 1px #000, 492px 15px 0 2px #ffffff,
  1357px 1157px 0 1px #000, 1357px 1157px 0 2px #ffffff,
  637px 240px 0 1px #000, 637px 240px 0 2px #ffffff,
  1515px 281px 0 1px #000, 1515px 281px 0 2px #ffffff,
  1757px 682px 0 1px #000, 1757px 682px 0 2px #ffffff,
  1035px 1298px 0 1px #000, 1035px 1298px 0 2px #ffffff,
  1019px 1233px 0 1px #000, 1019px 1233px 0 2px #ffffff,
  25px 162px 0 1px #000, 25px 162px 0 2px #ffffff,
  262px 884px 0 1px #000, 262px 884px 0 2px #ffffff,
  1328px 965px 0 1px #000, 1328px 965px 0 2px #ffffff,
  984px 879px 0 1px #000, 984px 879px 0 2px #ffffff,
  1213px 689px 0 1px #000, 1213px 689px 0 2px #ffffff,
  623px 1091px 0 1px #000, 623px 1091px 0 2px #ffffff,
  1227px 422px 0 1px #000, 1227px 422px 0 2px #ffffff,
  1977px 1025px 0 1px #000, 1977px 1025px 0 2px #ffffff,
  620px 474px 0 1px #000, 620px 474px 0 2px #ffffff,
  1978px 1025px 0 1px #000, 1978px 1025px 0 2px #ffffff,
  621px 474px 0 1px #000, 621px 474px 0 2px #ffffff,
  150px 200px 0 1px #000, 150px 200px 0 2px #ffffff,
  800px 600px 0 1px #000, 800px 600px 0 2px #ffffff,
  50px 75px 0 1px #000, 50px 75px 0 2px #ffffff,
  1024px 768px 0 1px #000, 1024px 768px 0 2px #ffffff,
  320px 240px 0 1px #000, 320px 240px 0 2px #ffffff,
  960px 5400px 0 1px #000, 960px 5400px 0 2px #ffffff,
  300px 5000px 0 1px #000, 300px 5000px 0 2px #ffffff,
  800px 2000px 0 1px #000, 800px 2000px 0 2px #ffffff,
  400px 6000px 0 1px #000, 400px 6000px 0 2px #ffffff,
  1920px 1080px 0 1px #000, 1920px 1080px 0 2px #ffffff,
  250px 3500px 0 1px #000, 250px 3500px 0 2px #ffffff,
  1366px 7680px 0 1px #000, 1366px 7680px 0 2px #ffffff,
  1080px 7200px 0 1px #000, 1080px 7200px 0 2px #ffffff,
  1600px 9000px 0 1px #000, 1600px 9000px 0 2px #ffffff,
  1440px 2560px 0 1px #000, 1440px 2560px 0 2px #ffffff,
  768px 1024px 0 1px #000, 768px 1024px 0 2px #ffffff,
  480px 800px 0 1px #000, 480px 800px 0 2px #ffffff,
  220px 180px 0 1px #000, 220px 180px 0 2px #ffffff,
  -200px 500px 0 1px #000, -200px 500px 0 2px #ffffff,
  -543px 768px 0 1px #000, -543px 768px 0 2px #ffffff,
  -987px 234px 0 1px #000, -987px 234px 0 2px #ffffff,
  -321px 456px 0 1px #000, -321px 456px 0 2px #ffffff,
  -876px 789px 0 1px #000, -876px 789px 0 2px #ffffff,
  -654px 987px 0 1px #000, -654px 987px 0 2px #ffffff,
  -234px 567px 0 1px #000, -234px 567px 0 2px #ffffff,
  -876px 123px 0 1px #000, -876px 123px 0 2px #ffffff,
  -432px 876px 0 1px #000, -432px 876px 0 2px #ffffff,
  -765px 345px 0 1px #000, -765px 345px 0 2px #ffffff,
  -876px 987px 0 1px #000, -876px 987px 0 2px #ffffff,
  -654px 210px 0 1px #000, -654px 210px 0 2px #ffffff,
  -324px 543px 0 1px #000, -324px 543px 0 2px #ffffff,
  -876px 210px 0 1px #000, -876px 210px 0 2px #ffffff,
  -543px 987px 0 1px #000, -543px 987px 0 2px #ffffff,
  -765px 432px 0 1px #000, -765px 432px 0 2px #ffffff,
  -987px 654px 0 1px #000, -987px 654px 0 2px #ffffff,
  -234px 876px 0 1px #000, -234px 876px 0 2px #ffffff,
  -876px 765px 0 1px #000, -876px 765px 0 2px #ffffff,
  -456px 987px 0 1px #000, -456px 987px 0 2px #ffffff,
  -678px 543px 0 1px #000, -678px 543px 0 2px #ffffff,
  -543px 876px 0 1px #000, -543px 876px 0 2px #ffffff,
  -765px 234px 0 1px #000, -765px 234px 0 2px #ffffff,
  -666px 2345px 0 1px #000, -666px 2345px 0 2px #ffffff,
  1440px 5555px 0 1px #000, 1440px 5555px 0 2px #ffffff,
  768px 4123px 0 1px #000, 768px 4123px 0 2px #ffffff,
  480px 4657px 0 1px #000, 480px 4657px 0 2px #ffffff,
  220px 3678px 0 1px #000, 220px 3678px 0 2px #ffffff,
  -200px 3876px 0 1px #000, -200px 3876px 0 2px #ffffff,
  -543px 2000px 0 1px #000, -543px 2000px 0 2px #ffffff,
  -987px 5432px 0 1px #000, -987px 5432px 0 2px #ffffff,
  -321px 4523px 0 1px #000, -321px 4523px 0 2px #ffffff,
  -432px 4312px 0 1px #000, -432px 4312px 0 2px #ffffff,
  1098px 2365px 0 1px #000, 1098px 2365px 0 2px #ffffff,
  283px 5213px 0 1px #000, 283px 5213px 0 2px #ffffff,
  -876px 4789px 0 1px #000, -876px 4789px 0 2px #ffffff,
  765px 6000px 0 1px #000, 765px 6000px 0 2px #ffffff,
  -152px 2750px 0 1px #000, -152px 2750px 0 2px #ffffff,
  420px 8009px 0 1px #000, 420px 8009px 0 2px #ffffff,
  -867px 9876px 0 1px #000, -867px 9876px 0 2px #ffffff,
  325px 6045px 0 1px #000, 325px 6045px 0 2px #ffffff,
  -1298px 4562px 0 1px #000, -1298px 4562px 0 2px #ffffff,
  2147px 7980px 0 1px #000, 2147px 7980px 0 2px #ffffff,
  -375px 3245px 0 1px #000, -375px 3245px 0 2px #ffffff,
  624px 9321px 0 1px #000, 624px 9321px 0 2px #ffffff,
  -999px 6132px 0 1px #000, -999px 6132px 0 2px #ffffff,
  1876px 4620px 0 1px #000, 1876px 4620px 0 2px #ffffff,
  -654px 8201px 0 1px #000, -654px 8201px 0 2px #ffffff,
  768px 9845px 0 1px #000, 768px 9845px 0 2px #ffffff,
  -2210px 2999px 0 1px #000, -2210px 2999px 0 2px #ffffff,
  452px 6311px 0 1px #000, 452px 6311px 0 2px #ffffff,
  -666px 2345px 0 1px #000, -666px 2345px 0 2px #ffffff,
  1440px 5555px 0 1px #000, 1440px 5555px 0 2px #ffffff,
  768px 4123px 0 1px #000, 768px 4123px 0 2px #ffffff,
  480px 4657px 0 1px #000, 480px 4657px 0 2px #ffffff,
  220px 3678px 0 1px #000, 220px 3678px 0 2px #ffffff,
  -200px 3876px 0 1px #000, -200px 3876px 0 2px #ffffff,
  -543px 2000px 0 1px #000, -543px 2000px 0 2px #ffffff,
  -987px 5432px 0 1px #000, -987px 5432px 0 2px #ffffff,
  -321px 4523px 0 1px #000, -321px 4523px 0 2px #ffffff,
  -432px 4312px 0 1px #000, -432px 4312px 0 2px #ffffff,
  1098px 2365px 0 1px #000, 1098px 2365px 0 2px #ffffff,
  283px 5213px 0 1px #000, 283px 5213px 0 2px #ffffff,
  -876px 4789px 0 1px #000, -876px 4789px 0 2px #ffffff,
  765px 6000px 0 1px #000, 765px 6000px 0 2px #ffffff,
  -152px 2750px 0 1px #000, -152px 2750px 0 2px #ffffff,
  420px 8009px 0 1px #000, 420px 8009px 0 2px #ffffff,
  -867px 9876px 0 1px #000, -867px 9876px 0 2px #ffffff,
  325px 6045px 0 1px #000, 325px 6045px 0 2px #ffffff,
  -1298px 4562px 0 1px #000, -1298px 4562px 0 2px #ffffff,
  2147px 7980px 0 1px #000, 2147px 7980px 0 2px #ffffff,
  -375px 3245px 0 1px #000, -375px 3245px 0 2px #ffffff,
  624px 9321px 0 1px #000, 624px 9321px 0 2px #ffffff,
  -999px 6132px 0 1px #000, -999px 6132px 0 2px #ffffff,
  1876px 4620px 0 1px #000, 1876px 4620px 0 2px #ffffff,
  -654px 8201px 0 1px #000, -654px 8201px 0 2px #ffffff,
  768px 9845px 0 1px #000, 768px 9845px 0 2px #ffffff,
  -2210px 2999px 0 1px #000, -2210px 2999px 0 2px #ffffff,
  452px 6311px 0 1px #000, 452px 6311px 0 2px #ffffff,
  982px 7098px 0 1px #000, 982px 7098px 0 2px #ffffff,
  -541px 8562px 0 1px #000, -541px 8562px 0 2px #ffffff,
  3420px 4823px 0 1px #000, 3420px 4823px 0 2px #ffffff,
  -1500px 6354px 0 1px #000, -1500px 6354px 0 2px #ffffff,
  2298px 7650px 0 1px #000, 2298px 7650px 0 2px #ffffff,
  -1875px 3687px 0 1px #000, -1875px 3687px 0 2px #ffffff,
  5423px 9345px 0 1px #000, 5423px 9345px 0 2px #ffffff,
  -2998px 5201px 0 1px #000, -2998px 5201px 0 2px #ffffff,
  731px 9900px 0 1px #000, 731px 9900px 0 2px #ffffff,
  -666px 5234px 0 1px #000, -666px 5234px 0 2px #ffffff,
  1440px 5890px 0 1px #000, 1440px 5890px 0 2px #ffffff,
  768px 5268px 0 1px #000, 768px 5268px 0 2px #ffffff,
  480px 5150px 0 1px #000, 480px 5150px 0 2px #ffffff,
  220px 5796px 0 1px #000, 220px 5796px 0 2px #ffffff,
  -200px 5432px 0 1px #000, -200px 5432px 0 2px #ffffff,
  -543px 5000px 0 1px #000, -543px 5000px 0 2px #ffffff,
  -987px 5543px 0 1px #000, -987px 5543px 0 2px #ffffff,
  -321px 5790px 0 1px #000, -321px 5790px 0 2px #ffffff,
  -432px 5867px 0 1px #000, -432px 5867px 0 2px #ffffff,
  1098px 5690px 0 1px #000, 1098px 5690px 0 2px #ffffff,
  283px 5289px 0 1px #000, 283px 5289px 0 2px #ffffff,
  -876px 5076px 0 1px #000, -876px 5076px 0 2px #ffffff,
  765px 5778px 0 1px #000, 765px 5778px 0 2px #ffffff,
  -152px 5999px 0 1px #000, -152px 5999px 0 2px #ffffff,
  420px 5367px 0 1px #000, 420px 5367px 0 2px #ffffff,
  -867px 5056px 0 1px #000, -867px 5056px 0 2px #ffffff,
  325px 5201px 0 1px #000, 325px 5201px 0 2px #ffffff,
  -1298px 5312px 0 1px #000, -1298px 5312px 0 2px #ffffff,
  2147px 5779px 0 1px #000, 2147px 5779px 0 2px #ffffff,
  -375px 5487px 0 1px #000, -375px 5487px 0 2px #ffffff,
  624px 5550px 0 1px #000, 624px 5550px 0 2px #ffffff,
  -999px 5159px 0 1px #000, -999px 5159px 0 2px #ffffff,
  1876px 5932px 0 1px #000, 1876px 5932px 0 2px #ffffff,
  -654px 5246px 0 1px #000, -654px 5246px 0 2px #ffffff,
  768px 5178px 0 1px #000, 768px 5178px 0 2px #ffffff,
  -2210px 5450px 0 1px #000, -2210px 5450px 0 2px #ffffff,
  452px 5892px 0 1px #000, 452px 5892px 0 2px #ffffff,
  982px 5938px 0 1px #000, 982px 5938px 0 2px #ffffff,
  -541px 5690px 0 1px #000, -541px 5690px 0 2px #ffffff,
  3420px 5700px 0 1px #000, 3420px 5700px 0 2px #ffffff,
  -1500px 5643px 0 1px #000, -1500px 5643px 0 2px #ffffff,
  2298px 5555px 0 1px #000, 2298px 5555px 0 2px #ffffff,
  -1875px 5102px 0 1px #000, -1875px 5102px 0 2px #ffffff,
  5423px 5234px 0 1px #000, 5423px 5234px 0 2px #ffffff,
  -2998px 5445px 0 1px #000, -2998px 5445px 0 2px #ffffff,
  731px 5990px 0 1px #000, 731px 5990px 0 2px #ffffff,
  528px 1525px 0 1px #000, 528px 1525px 0 2px #ffffff;
  animation: animStar 71s linear infinite;
}

#stars2 {
  width: 1px; 
  height: 11px; 
  background: transparent;
  z-index: 1;
  box-shadow:
  150px 150px 0 0 #fff, 
  300px 300px 0 0 #fff,
  450px 450px 0 0 #fff,
  600px 600px 0 0 #fff,
  189.596px 110.404px 0 0 #fff,
  880px 320px 0 0 #fff,
  189.5959596px 110.4040404px 0 0 #fff,
  41.11111111px 258.8888889px 0 0 #fff,
  246.1616162px 53.83838384px 0 0 #fff,
  880px 320px 0 0 #fff,
  243.6363636px 956.3636364px 0 0 #fff,
  491.1111111px 708.8888889px 0 0 #fff,
  738.5858586px 461.4141414px 0 0 #fff,
  102.2222222px 1097.777778px 0 0 #fff,
  1021.414141px 178.5858586px 0 0 #fff,
  724.4444444px 475.5555556px 0 0 #fff,
  809.2929293px 390.7070707px 0 0 #fff,
  900px 900px 0 0 #fff,
  967.8787879px 832.1212121px 0 0 #fff,
  1087.373737px 712.6262626px 0 0 #fff,
  811.6161616px 988.3838384px 0 0 #fff,
  700.6060606px 1099.393939px 0 0 #fff,
  534.4444444px 1265.555556px 0 0 #fff,
  262.2222222px 1537.777778px 0 0 #fff,
  161.8181818px 1638.181818px 0 0 #fff,
  753.7373737px 3446.262626px 0 0 #fff,
  683.030303px 3516.969697px 0 0 #fff,
  612.3232323px 3587.676768px 0 0 #fff,
  470.9090909px 3729.090909px 0 0 #fff,
  117.3737374px 4082.626263px 0 0 #fff,
  46.66666667px 4153.333333px 0 0 #fff,
  -94.74747475px 4294.747475px 0 0 #fff,
  -306.8686869px 4506.868687px 0 0 #fff,
  -377.5757576px 4577.575758px 0 0 #fff,
  -518.989899px 4718.989899px 0 0 #fff,
  -660.4040404px 4860.40404px 0 0 #fff,
  -801.8181818px 5001.818182px 0 0 #fff,
  -872.5252525px 5072.525253px 0 0 #fff,
  419.3939394px 1980.606061px 0 0 #fff,
  277.979798px 2122.020202px 0 0 #fff,
  136.5656566px 2263.434343px 0 0 #fff,
  65.85858586px 2334.141414px 0 0 #fff,
  -4.848484848px 2404.848485px 0 0 #fff,
  -146.2626263px 2546.262626px 0 0 #fff,
  -358.3838384px 2758.383838px 0 0 #fff,
  -499.7979798px 2899.79798px 0 0 #fff,
  -570.5050505px 2970.505051px 0 0 #fff,
  -570.5050505px 2970.505051px 0 0 #fff,
  790.1010101px 2209.89899px 0 0 #fff,
  491.010101px 2508.989899px 0 0 #fff,
  568.7878788px 2431.212121px 0 0 #fff,
  253.4343434px 2746.565657px 0 0 #fff,
  139.5959596px 2860.40404px 0 0 #fff,
  -9.595959596px 3009.59596px 0 0 #fff,
  -473.4343434px 3473.434343px 0 0 #fff,
  -315.7575758px 3315.757576px 0 0 #fff,
  -192.7272727px 3192.727273px 0 0 #fff,
  -617.6767677px 3617.676768px 0 0 #fff,
  -812.8282828px 3812.828283px 0 0 #fff,
  -1024.949495px 4024.949495px 0 0 #fff,
  1990.10101px 3409.89899px 0 0 #fff,
  -131.1111111px 5531.111111px 0 0 #fff,
  -766.0606061px 6166.060606px 0 0 #fff,
  32.92929293px 5367.070707px 0 0 #fff,
  -838.1818182px 6238.181818px 0 0 #fff,
  -930.8080808px 6330.808081px 0 0 #fff,
  663.6363636px 4736.363636px 0 0 #fff,
  1507.171717px 3892.828283px 0 0 #fff,
  989.5959596px 4410.40404px 0 0 #fff,
  481.2121212px 4918.787879px 0 0 #fff,
  865.8585859px 4534.141414px 0 0 #fff;
  animation: animStar 71s linear infinite;

}

#stars2a {
  width: 6px;
  height: 1.5px; 
  z-index: 1;
  background: transparent;
  rotate: -45deg;
  box-shadow:
    4px 203px 0 0 #fff,
    4px 415px 0 0 #fff, 
    4px 627px 0 0 #fff, 
    4px 839px 0 0 #fff, 
    60px 203px 0 0 #fff, 
    400px 839px 0 0 #fff,
    60px 203px 0 0 #fff,
    -150px 203px 0 0 #fff,
    140px 203px 0 0 #fff,
    400px 839px 0 0 #fff,
    -500px 839px 0 0 #fff,
    -150px 839px 0 0 #fff,
    200px 839px 0 0 #fff,
    -700px 839px 0 0 #fff,
    600px 839px 0 0 #fff,
    180px 839px 0 0 #fff,
    300px 839px 0 0 #fff,
    4px 1263px 0 0 #fff,
    100px 1263px 0 0 #fff,
    269px 1263px 0 0 #fff,
    -121px 1263px 0 0 #fff,
    -278px 1263px 0 0 #fff,
    -513px 1263px 0 0 #fff,
    -898px 1263px 0 0 #fff,
    -1040px 1263px 0 0 #fff,
    -1900px 2959px 0 0 #fff,
    -2000px 2959px 0 0 #fff,
    -2100px 2959px 0 0 #fff,
    -2300px 2959px 0 0 #fff,
    -2800px 2959px 0 0 #fff,
    -2900px 2959px 0 0 #fff,
    -3100px 2959px 0 0 #fff,
    -3400px 2959px 0 0 #fff,
    -3500px 2959px 0 0 #fff,
    -3700px 2959px 0 0 #fff,
    -3900px 2959px 0 0 #fff,
    -4100px 2959px 0 0 #fff,
    -4200px 2959px 0 0 #fff,
    -1100px 1687px 0 0 #fff,
    -1300px 1687px 0 0 #fff,
    -1500px 1687px 0 0 #fff,
    -1600px 1687px 0 0 #fff,
    -1700px 1687px 0 0 #fff,
    -1900px 1687px 0 0 #fff,
    -2200px 1687px 0 0 #fff,
    -2400px 1687px 0 0 #fff,
    -2500px 1687px 0 0 #fff,
    -1000px 2111px 0 0 #fff,
    -1423px 2111px 0 0 #fff,
    -1313px 2111px 0 0 #fff,
    -1759px 2111px 0 0 #fff,
    -1920px 2111px 0 0 #fff,
    -2131px 2111px 0 0 #fff,
    -2787px 2111px 0 0 #fff,
    -2564px 2111px 0 0 #fff,
    -2390px 2111px 0 0 #fff,
    -2991px 2111px 0 0 #fff,
    -3267px 2111px 0 0 #fff,
    -3567px 2111px 0 0 #fff,
    -1000px 3807px 0 0 #fff,
    -4000px 3807px 0 0 #fff,
    -4898px 3807px 0 0 #fff,
    -3768px 3807px 0 0 #fff,
    -5000px 3807px 0 0 #fff,
    -5131px 3807px 0 0 #fff,
    -2876px 3807px 0 0 #fff,
    -1683px 3807px 0 0 #fff,
    -2415px 3807px 0 0 #fff,
    -3134px 3807px 0 0 #fff,
    -2590px 3807px 0 0 #fff,
    -4343px 3807px 0 0 #fff,
    1990.10101px 3409.89899px 0 0 #fff;
  animation: animRotate 71s linear infinite;

}

#star-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-width:700px){
  #stars{
    display: none;
  }
  #stars2{
    display: none;
  }
  #stars2a{
    display: none;
  } 
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@keyframes animRotate {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(1414.21356px, -1414.21356px); 
  }
}