.sponsors-page * {
  overflow-x: visible !important;
  height: fit-content;
  position: relative;
  z-index: 2;
}
.sponsors-page .sponsors-content {
  height: 100%;
}
.sponsors-page .sponsors-header {
  height: 5vh;
}
.sponsors-page .sponsors-footer {
  height: 20vh;
}
.sponsors-page .sponsors-title {
  font-family: "Montserrat", monospace;
  font-weight: 600;
  font-size: 5em;
  color: whitesmoke;
  padding-bottom: 15px;
}
.sponsors-container {
  width: 76vw;
  margin: auto;
  border: 5px solid #ffffff;
  border-radius: 30px;
}

#plat-sponsors-container {
  margin-top: 6vh;
  background-color: #c0578e;
}
#gold-sponsors-container {
  background-color: #5392be;
}
#silver-sponsors-container {
  background-color: #363a65;
}
#bronze-sponsors-container {
  background-color: #52bdcd; 
}

.sponsors-level {

  font-family: "Montserrat", monospace;
  font-weight: 600;
  font-size: 3.5em;
  color: white;
  width: 65vw;
  margin: 4vh auto 2vh auto;
  /* border: 5px solid #b300ff; */
  /* border-radius: 30px;
  margin-bottom: 5vh; */
}
.sponsors-page .sponsors-q-a-container {
  margin: 2vh auto;
  display: flex;
  flex-direction: column;
}

.row-content {
  padding: 5px;
}

.sponsors {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 70vw;
  margin: 1rem auto;
  /* border-radius: 20px !important; */
}

.sponsors img:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.sponsors.sponsors-platinum img {
  width: 80%;
  /* border-radius: 20px; */
}

.sponsors.sponsors-platinum a {
  width: 30vh;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 2em;
}

.sponsors.sponsors-gold img {
  width: 100%;
  /* border-radius: 20px; */
}

.sponsors.sponsors-gold a {
  width: 30vh;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 1em;
}

.sponsors .rounded-sponsor-img img {
  border-radius: 3vh;
}

.sponsors .hrt-logo {
  width: 30vh !important;
}


.sponsors.sponsors-silver img {
  width: 80%;
  /* border-radius: 20px; */
}

.sponsors.sponsors-silver a {
  width: 15%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 2em;
}

.sponsors.sponsors-silver img {
  width: 100%;
  /* border-radius: 20px; */
}

.sponsors.sponsors-bronze {
  margin-bottom: 0.5rem;
}
.sponsors.sponsors-bronze img {
  width: 15vh;
}
.sponsors.sponsors-bronze a {
  width: 100vh;
  /* min-height: 100px; */
  margin: 10px 10px;
}




@media only screen and (max-width: 460px) {
  .sponsors-page .sponsors-title {
    font-size: 4.5em;
  }
  .sponsors-level {
    font-weight: 600;
    font-size: 2.5em;
    margin: auto;
  }
  .sponsors img {
    height: auto;
    max-width: auto !important;
  }
  .sponsors .hrt-logo {
    max-width: auto !important;
    /* margin-left: 0px !important; */
    margin-left: auto !important;
    margin-right: auto !important ;
  }
}
