@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

* {
  /* font-family: "IBM Plex Mono"; */

  /* variables */
  --navbar-height: max(8vh, 40px);

  /* variables used for faq page*/
  --row-title-text-size: 1.2em;
  --row-content-color: white;
  --row-content-text-size: 1.2em;
  font-family: "Montserrat", monospace;
  font-weight: 400;
}

.hackcmu {
  text-align: center;
  background-color: black;
}
