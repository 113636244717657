#terminal {
  min-width: 100%;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 60px;
  padding: 0px 30px 100px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#navbar {
  font-size: 14px;
  position: fixed;
  color: var(--color-text-subtle);
  top: 0;
  width: 100%;
  background-color: var(--color-background);
  border-bottom: 1px solid var(--color-border);
  overflow: auto;
  z-index: 3;
}

#navbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

#navbar li a {
  color: hotpink;
  display: block;
  padding: 15px 30px;
  border-top: 1px solid var(--color-border);
  text-decoration: none;
}

#bash {
  display: inline-block;
  width: auto;
  padding: 15px 20px 15px;
}

#navigation {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

#menu-icon {
  cursor: pointer;
  float: right;
  padding: 25px 20px 10px;
  user-select: none;
}

#menu-icon .navicon {
  display: block;
  height: 2px;
  position: relative;
  width: 18px;
}

#menu-btn {
  display: none;
}

#menu-icon .navicon:before,
#menu-icon .navicon:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

/* menu */

#navbar #navigation {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

#navbar #menu-icon .navicon {
  background: var(--color-text-subtle);
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

#navbar #menu-icon .navicon:before,
#navbar #menu-icon .navicon:after {
  background: var(--color-text-subtle);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

#navbar #menu-icon .navicon:before {
  top: 5px;
}

#navbar #menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

#navbar #menu-btn {
  display: none;
}

#navbar #menu-btn:checked ~ #navigation {
  max-height: 240px;
}

#navbar #menu-btn:checked ~ #menu-icon .navicon {
  background: transparent;
}

#navbar #menu-btn:checked ~ #menu-icon .navicon:before {
  transform: rotate(-45deg);
}

#navbar #menu-btn:checked ~ #menu-icon .navicon:after {
  transform: rotate(45deg);
}

#navbar #menu-btn:checked ~ #menu-icon:not(.steps) .navicon:before,
#navbar #menu-btn:checked ~ #menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media screen and (min-width: 550px) {
  #navbar li {
    float: left;
  }
  #navbar li a {
    padding: 15px 15px 15px;
  }
  #navbar #navigation {
    clear: none;
    float: right;
    max-height: none;
  }
  #navbar #menu-icon {
    display: none;
  }
}

#input {
  font-size: 13px;
  display: block;
  line-height: 1;
}

#prompt {
  margin-right: 0.75em;
  color: var(--color-text-subtle);
  display: inline-block;
  vertical-align: middle;
}

[cursor]:after {
  content: attr(cursor);
  font-family: monospace;
  margin-left: 0.3em;
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

/* Cursor animation */

@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.text {
  font-family: "Lato", sans-serif;
  text-align: center;
}

.title {
  font-weight: 150;
  font-family: "Montserrat", sans-serif;
}

.container {
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 80px);
  justify-content: center;
  align-items: center;
}

#welcometext {
  padding-bottom: 30px;
  font-size: calc(25px + 4.5vw);
}

#header {
  padding: 20px 0px;
  font-size: 35px;
}

.infotext {
  margin: 0 auto;
  font-weight: 300;
  padding: 10px 0px 0px;
  line-height: 1.5;
  width: 75%;
}

#static-text {
  top: 60px; 
  left: 20px;
  color: white;
  z-index: 1000;
}

#questions {
  padding-top: 30px;
}

#userprompt {
  display: inline-block;
}

.usertext {
  display: inline-block;
}

.output,
#userinput,
#links {
  word-wrap: break-word;
  padding: 4px 0px 4px 0px;
  line-height: 1;
  display: block;
}

#footer {
  font-size: 10px;
  font-family: "Lato", sans-serif;
  color: var(--color-text);
  background-color: var(--color-background);
  position: fixed;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
  bottom: 0px;
}

#learn-more-button {
  margin-top: 40px;
  margin-bottom: 50px;
  padding: 5px 10px;
  border: 1px solid var(--color-border);
  color: var(--color-text);
  text-decoration: none;
}

#learn-more-button:hover {
  color: var(--color-background);
  background-color: var(--color-text);
  cursor: pointer;
}

#what-we-do-container {
  width: 80%;
  line-height: 1.4;
  padding-bottom: 80px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.we-do {
  padding-top: 50px;
  width: 30vw;
  text-align: left;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.we-do h2 {
  margin: 0px 0px 5px;
}

.we-do-image i {
  margin-right: 10px;
}

.plat-tier-label {
  font-size: 30px;
  color: hotpink;
  margin-top: 5vh;
}

.gold-tier-label {
  font-size: 30px;
  color: hotpink;
}

.silver-tier-label {
  font-size: 30px;
  color: hotpink;
}

.bronze-tier-label {
  font-size: 30px;
  color: hotpink;
}

.grid {
  margin-top: 3vh;
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 50px;
}



#logo-grid img {
  padding: 20px 30px;
  height: 3.5vh;
}

img#asml {
  height: 3.5vh;
}

img#bam {
  height: 4vh;
}

img#bloomberg {
  height: 4.5vh;
}

img#js {
  height: 8vh;
}

img#deshaw {
  height: 5vh;
}

img#dropbox {
  height: 5vh;
}

img#gm {
  height: 10vh;
}

img#gsk {
  height: 8vh;
}

img#google {
  height: 7vh;
}

img#liquidnet {
  height: 5vh;
  width: auto;
}

img#microsoft {
  height: 5.5vh;
}

img#nutanix {
  height: 3vh;
}

img#omc {
  height: 8vh;
}
img#oracle {
  height: 4vh;
}

img#riotgames {
  height: 5.5vh;
}

img#sandia {
  height: 7.5vh;
}

img#scm {
  height: 11vh;
}
img#meta {
  height: 13vh;
}
img#hrt {
  height: 11vh;
}

#events-grid img {
  padding: 15px 30px;
  height: 27vh;
}

#profile-container {
  margin-top: 5vh;
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.profile {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  cursor: pointer;
  margin: 20px 40px;
}

.card {
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr;
  width: 240px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 600ms;
  transition: all 600ms;
  z-index: 20;
}

.card > div {
  padding: 20px 20px;
  border-radius: 25px;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 200px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card .card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: var(--color-card-background);
}

.card-button {
  display: none;
}

.profile:hover .card {
  -webkit-transform: rotateY(10deg);
  transform: rotateY(10deg);
}

.profile:hover .card-front {
  background: var(--color-card-background);
}

:checked + .card {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.profile:hover :checked + .card {
  transform: rotateY(170deg);
  -webkit-transform: rotateY(170deg);
}

.cropper {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
}

.cropper img {
  width: 200px;
}

.profile-bio {
  padding-top: 10px;
  overflow: auto;
  width: 200px;
  height: 180px;
  text-align: left;
}

.profile-bio::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.profile-links {
  text-align: right;
  height: 20px;
}

.linkedin img {
  height: 20px;
}

.profile-name {
  font-size: 20px;
  padding-top: 10px;
}

.card-back .profile-name {
  padding-top: 0px;
}

.profile-title {
  font-size: 16px;
  padding-top: 2px;
  word-wrap: break-word;
}

#misha img {
  width: 210px;
}

#clara img {
  width: auto;
  height: 200px;
  margin-left: -50px;
}

#audrey img {
  width: 210px;
}

#olivia img {
  width: auto;
  height: 200px;
}

#check-later {
  padding-bottom: 40px;
}

@media screen and (max-width: 800px) {
  #terminal {
    padding: 0px 25px 100px;
  }

  #header {
    padding-top: 20px;
  }

  .infotext {
    width: 95%;
  }

  #profile-container {
    width: 100%;
  }

  .profile {
    margin: 20px 15px;
  }

  #logo-grid {
    width: 100%;
  }

  #what-we-do-container {
    width: 95%;
    line-height: 1.4;
    padding-bottom: 100px;
  }

  .row {
    flex-flow: column;
    justify-content: center;
    width: 100%;
  }

  .we-do {
    padding-top: 20px;
    width: 100%;
  }

  .profile:hover .card {
    -webkit-transform: unset;
    transform: unset;
  }

  :checked + .card {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }

  .profile:hover :checked + .card {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }
}
